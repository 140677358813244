import CloseIcon from "@mui/icons-material/Close";

import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Divider,
	Grid,
	IconButton,
	InputLabel,
	OutlinedInput,
	Typography,
} from "@mui/material";
import React from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { CircleImg1, CircleImg2, Vector } from "../../images";
import { useStyles } from "./heroStyles";

const Hero = () => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [btnHover, setBtnHover] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className={classes.heroGridContainer}
		>
			<Grid item xs={12} sm={7}>
				<Typography className={classes.heroText}>
					BE CREATIVE – WORK INTUITIVELY.
					<br />
					<span className={classes.heroSmallerText}>
						We support users who think of creative and intuitive ways to execute
						routine financial processes
					</span>
				</Typography>

				<Grid className={classes.gridGrid}>
					<Divider className={classes.gridBorder} />
				</Grid>
				<Typography className={classes.heroMetaText}>
					Join us in our creative journey.
				</Typography>
				<br />

				<Button
					className={classes.scheduleDemoBtn}
					sx={{ marginTop: "3.125rem" }}
					onClick={handleOpen}
					onMouseEnter={() => {
						setBtnHover(true);
					}}
					onMouseLeave={() => {
						setBtnHover(false);
					}}
				>
					<Typography className={classes.BtnText}>Schedule Demo</Typography>
					{btnHover && (
						<Box display="flex" ml={2}>
							<img src={Vector} alt="arrow" />
						</Box>
					)}
				</Button>

				<Dialog
					open={open}
					onClose={handleClose}
					className={classes.formBoxLayer}
				>
					<DialogContent>
						<Box>
							<Grid
								container
								justifyContent="center"
								className={classes.formBox}
								rowSpacing={1}
							>
								<Grid item xs={12} container>
									<Grid item container justifyContent="space-between">
										<Grid item xs={11}>
											<Typography className={classes.mainText}>
												Like to review Data Sheet or schedule a Demo?
											</Typography>
										</Grid>
										<Grid item xs={1}>
											<IconButton size="large">
												<CloseIcon onClick={handleClose} size="large" />
											</IconButton>
										</Grid>
									</Grid>

									<Typography className={classes.mainMetaText}>
										We can help you...
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<InputLabel
										htmlFor="full-name-id"
										className={classes.fullNameText}
									>
										Your Name
									</InputLabel>

									<OutlinedInput
										id="full-name-id"
										name="name"
										placeholder="Enter your full name"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel
										htmlFor="full-company-id"
										className={classes.fullNameText}
									>
										Company
									</InputLabel>

									<OutlinedInput
										id="full-company-id"
										name="company"
										placeholder="Enter your company name"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel
										htmlFor="mail-id"
										className={classes.fullNameText}
									>
										Your Email
									</InputLabel>
									<OutlinedInput
										id="mail-id"
										name="mail"
										placeholder="Enter your email ID"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel
										htmlFor="full-contact-phone-id"
										className={classes.fullNameText}
									>
										Contact Phone
									</InputLabel>

									<OutlinedInput
										id="full-contact-phone-id"
										name="contact-phone"
										placeholder="Enter your contact number"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel htmlFor="msg-id" className={classes.fullNameText}>
										Message
									</InputLabel>
									<OutlinedInput
										multiline
										id="msg-id"
										name="message"
										placeholder="Start typing here..."
										minRows={5}
										fullWidth
									/>
								</Grid>

								<Grid item xs={12} container justifyContent="center">
									<Button className={classes.BtnText}>Submit</Button>
								</Grid>
							</Grid>
						</Box>
					</DialogContent>
				</Dialog>
			</Grid>
			<Grid item xs={12} sm={5} className={classes.swiperGrid}>
				<Swiper
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					slidesPerView={1}
					modules={[Autoplay]}
				>
					<SwiperSlide>
						<Box display="flex" justifyContent="center" alignItems="center">
							<CircleImg1 />
						</Box>
					</SwiperSlide>
					<SwiperSlide>
						<Box display="flex" justifyContent="center" alignItems="center">
							<CircleImg2 />
						</Box>
					</SwiperSlide>
				</Swiper>
			</Grid>
		</Grid>
	);
};

export default Hero;
