import { createTheme } from "@mui/material";

import colors from "./colors";

// Create a theme instance.
let theme = createTheme({
	palette: {
		secondary: {
			main: colors.btnGreen,
		},
		primary: {
			main: colors.backgroundBlue,
		},
		offWhite: {
			main: colors.offWhite,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					backgroundColor: "#00CC8F",
					borderRadius: "3.375rem",
					padding: "1.25rem 2.5rem",
					width: "15.375rem",
					height: "4.375rem",
					":hover": {
						backgroundColor: "#08A273",
						borderRadius: "3.375rem",
						padding: "1.25rem 2.5rem",
						width: "16.75rem",
						height: "4.375rem",
					},
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					borderRadius: "1.875rem",
				},
			},
		},
	},
});

export default theme;
