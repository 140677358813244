import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Container,
	Grid,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import React, { useState } from "react";
import { HEADER_ITEMS } from "../../constants";
import { useStyles } from "./headerStyles";

const HeaderItems = () => {
	const classes = useStyles();

	const handleScroll = (e, link) => {
		e.preventDefault();
		console.log("scrolling", link);
		document.querySelector(link).scrollIntoView();
	};

	return (
		<>
			{HEADER_ITEMS.map((val) => (
				<Grid
					item
					key={val.id}
					onClick={(e) => {
						handleScroll(e, val.itemLink);
					}}
				>
					<a
						href={val.itemLink}
						style={{
							textDecoration: "inherit",
							color: "inherit",
							cursor: "pointer",
						}}
					>
						<Typography className={classes.headerItem} align="center">
							{val.itemName}
						</Typography>
					</a>
				</Grid>
			))}
		</>
	);
};

const Header = () => {
	const classes = useStyles();
	const theme = useTheme();
	const isMobileView = useMediaQuery(theme.breakpoints.down(769));
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	return (
		<AppBar
			component="nav"
			position="static"
			color="primary"
			elevation="0"
			className={classes.root}
		>
			<Container
				sx={{
					minWidth: "80%",
				}}
			>
				<Toolbar className={classes.toolbar}>
					<Grid container className={classes.headerWrapper}>
						<Grid item xs={3} container justifyContent={"flex-start"}>
							<img src="images/logo.png" alt="logo" className={classes.logo} />
						</Grid>
						{isMobileView ? (
							<div onClick={handleMenu} className={classes.hamburgerWrapper}>
								<MenuIcon
									className={classes.hamburgerIcon}
									width="auto"
									height="2rem"
								/>
							</div>
						) : (
							<Grid
								item
								xs={9}
								container
								justifyContent="flex-end"
								alignItems="center"
								columnSpacing={4}
							>
								<HeaderItems />
							</Grid>
						)}
						<Drawer
							classes={{
								root: classes.drawerRoot,
							}}
							anchor={"right"}
							open={isMenuOpen}
							onClose={handleMenu}
						>
							<>
								<div onClick={handleMenu} className={classes.closeIconWrapper}>
									<CloseIcon />
								</div>
								<div className={classes.headerItemWrapper}>
									<HeaderItems />
								</div>
							</>
						</Drawer>
					</Grid>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default Header;
