import { makeStyles } from "@mui/styles";
import {
	CompanyLayer,
	HeroLayer,
	QueryLeftLayer,
	QueryRightLayer,
	ServiceDataBottomLeftLayer,
	ServiceDataTopRightLayer,
	ServicesTopRightLayer,
	SupportBottomLeftLayer,
	TechnologyLayer,
	TrainingBottomRightLayer,
} from "../../images";

export const useStyles = makeStyles((theme) => ({
	heroSection: {
		//height: "calc(100vh - 4rem)",
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${HeroLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom left -50px",
	},
	companySection: {
		backgroundColor: "#ffffff",
		backgroundImage: `url(${CompanyLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom left -50px",
	},
	serviceSection: {
		backgroundColor: theme.palette.primary.main,
		backgroundImage: `url(${ServiceDataBottomLeftLayer}), url(${ServiceDataTopRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -340px left -50px, top -300px right -50px",
	},
	technologySection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${TechnologyLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -80px right -80px",
	},
	solutionSection: {
		backgroundColor: "#FFFFFF",
	},
	servicesSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${ServicesTopRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 320px right -80px",
	},
	supportSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${SupportBottomLeftLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -110px left -70px",
	},
	trainingSection: {
		backgroundColor: "#F7F9FE",
		backgroundImage: `url(${TrainingBottomRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "bottom -100px right -70px",
	},
	partnersSection: {
		backgroundColor: "#FFFFFF",
		backgroundImage: `url(${SupportBottomLeftLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 80px left -70px",
	},
	querySection: {
		backgroundColor: "#0B3192",
		backgroundImage: `url(${QueryLeftLayer}), url(${QueryRightLayer})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "top 0px left -70px, top 350px right -30px",
	},
	bottomSection: {
		backgroundColor: "#FFFFFF",
	},
}));
