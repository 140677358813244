import {
	Box,
	Button,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	OutlinedInput,
	Typography,
} from "@mui/material";
import React from "react";
import { CallIcon, Linkedin, MailIcon, Vector } from "../../images";
import { useStyles } from "./queryStyles";

const Query = () => {
	const classes = useStyles();
	const [btnHover, setBtnHover] = React.useState(false);

	return (
		<Grid container className={classes.partnersGridContainer}>
			<Grid
				item
				md={6}
				sm={12}
				xs={12}
				sx={{ padding: "9rem 0" }}
				container
				alignItems="center"
			>
				<Grid item xs={12}>
					<Typography className={classes.queryText}>
						Need more information or have a question?
					</Typography>

					<Typography className={classes.queryMetaText} mt={3}>
						We can help you...
					</Typography>
				</Grid>

				<Grid item xs={12} className={classes.listItemsGrid}>
					<List>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									<MailIcon />
								</ListItemIcon>
								<ListItemText
									className={classes.listHeaderText}
									primary="contact@infomoksha.com"
								/>
							</ListItemButton>
						</ListItem>
						<ListItem disablePadding>
							<ListItemButton>
								<ListItemIcon>
									{" "}
									<CallIcon />
								</ListItemIcon>
								<ListItemText
									className={classes.listHeaderText}
									primary="+1-408-893-9750"
								/>
							</ListItemButton>
						</ListItem>

						<ListItem disablePadding>
							<Grid container mt={8}>
								<Grid item>
									<ListItemButton>
										<ListItemIcon>
											<a href="https://www.linkedin.com/company/infomoksha/">
												<img src={Linkedin} alt="linkedin" width="50px" />{" "}
											</a>
										</ListItemIcon>
									</ListItemButton>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</Grid>
			</Grid>
			<Grid
				item
				md={6}
				sm={12}
				xs={12}
				className={classes.circlesGrid}
				container
				justifyContent="center"
			>
				<Grid
					container
					justifyContent="center"
					className={classes.formBox}
					rowSpacing={1}
				>
					<Grid item container justifyContent="space-between">
						<Grid item xs={11}>
							<Typography className={classes.mainText}>
								Like to review Data Sheet or schedule a Demo?
							</Typography>
						</Grid>
						<Typography className={classes.mainMetaText}>
							We can help you...
						</Typography>
					</Grid>

					<Grid item xs={12}>
						<InputLabel htmlFor="full-name-id" className={classes.fullNameText}>
							Your Name
						</InputLabel>

						<OutlinedInput
							id="full-name-id"
							name="name"
							placeholder="Enter your full name"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel
							htmlFor="full-company-id"
							className={classes.fullNameText}
						>
							Company
						</InputLabel>

						<OutlinedInput
							id="full-company-id"
							name="company"
							placeholder="Enter your company name"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="mail-id" className={classes.fullNameText}>
							Your Email
						</InputLabel>
						<OutlinedInput
							id="mail-id"
							name="mail"
							placeholder="Enter your email ID"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel
							htmlFor="full-contact-phone-id"
							className={classes.fullNameText}
						>
							Contact Phone
						</InputLabel>

						<OutlinedInput
							id="full-contact-phone-id"
							name="contact-phone"
							placeholder="Enter your contact number"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel htmlFor="msg-id" className={classes.fullNameText}>
							Message
						</InputLabel>
						<OutlinedInput
							multiline
							id="msg-id"
							name="message"
							placeholder="Start typing here..."
							minRows={3}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							onMouseEnter={() => {
								setBtnHover(true);
							}}
							onMouseLeave={() => {
								setBtnHover(false);
							}}
						>
							<Typography className={classes.BtnText}>Submit</Typography>
							{btnHover && (
								<Box display="flex" ml={2}>
									<img src={Vector} alt="arrow" />
								</Box>
							)}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Query;
