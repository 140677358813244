import { Container } from "@mui/material";
import React from "react";
import Bottom from "../bottom";
import Company from "../company";
import Header from "../header/Header";
import Hero from "../hero";
import Query from "../query";
import ServiceData from "../servicedata";
import Services from "../services";
import Solution from "../solution";
import Support from "../support";
import Technology from "../technology";
import Trainings from "../trainings";

import { useStyles } from "./homeStyles";

const Home = () => {
	const classes = useStyles();

	return (
		<>
			<Header />
			<section id="1" className={classes.heroSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Hero />
				</Container>
			</section>
			<section id="company" className={classes.companySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Company />
				</Container>
			</section>
			<section id="3" className={classes.serviceSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<ServiceData />
				</Container>
			</section>
			<section id="technology" className={classes.technologySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Technology />
				</Container>
			</section>
			<section id="solutions" className={classes.solutionSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Solution />
				</Container>
			</section>
			<section id="services" className={classes.servicesSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Services />
				</Container>
			</section>
			<section id="7" className={classes.supportSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Support />
				</Container>
			</section>
			<section id="8" className={classes.trainingSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Trainings />
				</Container>
			</section>

			<section id="10" className={classes.querySection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Query />
				</Container>
			</section>
			<section id="partners" className={classes.bottomSection}>
				<Container
					sx={{
						minWidth: "80%",
					}}
				>
					<Bottom />
				</Container>
			</section>
		</>
	);
};

export default Home;
