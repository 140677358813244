import { makeStyles } from "@mui/styles";
import { Circles } from "../../images"; // Import using relative path

export const useStyles = makeStyles((theme) => ({
	partnersGridContainer: {
		padding: "6.25rem 0",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "0",
		},
	},
	partners: {
		fontSize: "3rem !important",
		fontWeight: 700,
		lineHeight: "3.75rem !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
	},
	gridBorderPro: {
		border: "2px solid #00CC8F",
		width: "6.25rem",
		//width: "13.549",
		height: "2px",
		marginTop: "0.875rem",
		marginBottom: "0.875rem",
	},
	queryText: {
		fontSize: "3rem !important",
		fontWeight: 600,
		lineHeight: "120% !important",
		fontFamily: "OutfitRegular",
		color: "#FFFFFF",
		[theme.breakpoints.down("sm")]: {
			fontSize: "2.5rem !important",
			fontWeight: 600,
			lineHeight: "120% !important",
		},
	},
	listItemsGrid: {
		[theme.breakpoints.down("sm")]: {
			marginTop: "2rem !important",
		},
	},
	queryMetaText: {
		fontSize: "2rem !important",
		fontWeight: 600,
		lineHeight: "100% !important",
		fontFamily: "OutfitRegular",
		color: "#FFFFFF",
	},
	listHeaderText: {
		fontSize: "1.25rem !important",
		fontWeight: 500,
		lineHeight: "1.563 !important",
		fontFamily: "OutfitRegular",
		color: "#FFFFFF",
	},
	circlesGrid: {
		backgroundImage: `url(${Circles})`,
		backgroundRepeat: "no-repeat",
		objectFit: "fill",
		backgroundSize: "contain",
		backgroundPosition: "center",
		paddingTop: "4rem",
		paddingBottom: "4rem",
	},
	formBox: {
		padding: "1.875rem",
		backgroundColor: "#FFFFFF",
		borderRadius: "1.875rem",
		width: "70% !important",
		[theme.breakpoints.down("sm")]: {
			width: "100% !important",
		},
	},
	fullNameText: {
		fontSize: "1.25rem !important",
		fontWeight: 500,
		lineHeight: "1.438 !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
	},
	BtnText: {
		color: "#fff !important",
		fontWeight: 500,
		fontSize: "1.5rem",
		lineHeight: "1.875rem",
	},
	mainText: {
		fontSize: "1.5rem !important",
		fontWeight: 600,
		lineHeight: "1.5rem !important",
		fontFamily: "OutfitRegular",
		color: "#0B3192",
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.5rem !important",
			fontWeight: 600,
			lineHeight: "2rem !important",
		},
	},
	mainMetaText: {
		fontSize: "1.5rem !important",
		fontWeight: 400,
		lineHeight: "40.8px !important",
		fontFamily: "OutfitRegular",
		color: "#222222",
		marginBottom: "0.5rem",
	},
}));
